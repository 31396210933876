import { Vue, Component } from 'vue-property-decorator'
import { Dropdown } from 'bootstrap-italia'

import MainHeader from "./components/header/main-header.vue";
import MainFooter from "./components/footer/main-footer.vue";
// import Cookies from '@/components/Cookies/cookies.vue';

@Component({
  name: 'frontOffice',
  components: {
    MainHeader,
    MainFooter,
    // Cookies
  },
})
export default class extends Vue {
  get key() {
    return this.$route.path;
  }

  mounted() {
    document.body.classList.remove("back-office")
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
      return new Dropdown(dropdownToggleEl)
    })
  }
}